import React from 'react'
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <div className="ctn-main">
      <h1 className="colored uk-text-center">NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
